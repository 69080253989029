export const environment = {
  production: true,
  hmr: false,
  env: 'smdc',
  firebase: {
    apiKey: 'AIzaSyBpnYfQHShpFwyI9FmiCJaaMkYgbMjDqJI',
    authDomain: 'sleip-smdc.firebaseapp.com',
    databaseURL: 'https://sleip-smdc-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'sleip-smdc',
    storageBucket: 'sleip-smdc.appspot.com',
    messagingSenderId: '639725444448',
    appId: '1:639725444448:web:3987d9868a3f0ef4b69205',
    measurementId: 'G-75QE8PX0Z2',
  },
};
